import type { Product } from "@/payload-types";
import { cn } from "~/util/cn";
import ProductCard from "./ProductCard";
import { nonNullable } from "~/util/nonNullable";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  docs: Product[];
  look?: "grid" | "list";
}

const ProductsGrid: React.FC<Props> = ({
  docs,
  look = "grid",
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={cn(
        "gap-4",
        look === "grid" && "sm:grid sm:grid-cols-2 md:grid-cols-3",
        look === "list" && "flex flex-col",
        className,
      )}
    >
      {docs.filter(nonNullable).map((item, index) => {
        return <ProductCard product={item} key={index} look={look} />;
      })}
    </div>
  );
};
export default ProductsGrid;
